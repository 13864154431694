import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Config } from "@qrcode/config";
import { Tabs } from "../../components/Tabs";
import { useState } from "react";
import copy from "copy-to-clipboard";
import CopyPNG from "../../assets/icons/copy-white.png";
import toast from "react-hot-toast";

interface CodeBlockProps {
  config: Config;
}

type Platform =
  | "react"
  | "react-native"
  | "node"
  | "angular"
  | "vue"
  | "vanilla";

const CODES: { [key in Platform]: string } = {
  react: `import { generateSVGString } from  '@intosoft/qrcode';

  const config = {}; // Paste config here
  const svgString = generateSVGString(config);
  
  export  const  RenderQR = () => {
    return (<div dangerouslySetInnerHTML={{__html: svgString}}/>);
  };
  `,
  "react-native": `// Prerequisite
// npm i react-native-svg | yarn add react-native-svg
import { SvgFromXml } from  "react-native-svg";
import { generateSVGString } from  '@intosoft/qrcode';

const config = {}; // Paste config here
const svgString = generateSVGString(config);

export  const  RenderQR = () => {
	return (<SvgFromXml  xml={svgString}  />);
};
`,
  node: `
const custoqr  = require("@intosoft/qrcode");

const config = {}; // paste config here
const svgString = custoqr.generateSVGString(config);
`,
  angular: `import { Component,ChangeDetectorRef } from '@angular/core';
  import { DomSanitizer, SafeHtml, } from '@angular/platform-browser';
  import { RouterOutlet } from '@angular/router';
  import { generateSVGString } from '@intosoft/qrcode';
  
  @Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    template: '<div [innerHTML]="svgString"></div>'
  })
  export class AppComponent  {
    title = 'angular';
    svgString: SafeHtml = "";
    constructor(private sanitizer: DomSanitizer,private cdr: ChangeDetectorRef) {}
   
    ngOnInit(): void {
      const config = {}; //paste config here
      const svgString = generateSVGString(config);
      this.svgString = this.sanitizer.bypassSecurityTrustHtml(svgString)
      this.cdr.detectChanges();
    }
  }
  `,
  vue: `<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { generateSVGString } from '@intosoft/qrcode';

export default defineComponent({
  setup() {
    const svgString = ref<string>('');

    onMounted(() => {
      const config = {}; // Paste config here
      svgString.value = generateSVGString(config);
    });

    return {
      svgString
    };
  }
});
</script>

<template>
<div v-html="svgString"></div>
</template>`,
  vanilla: `<!DOCTYPE html>
  <html>
    <body>
      <div id="svg-container"></div>
    </body>
    <script src="https://unpkg.com/@intosoft/qrcode@0.1.1/dist/iife/index.js"></script>
    <script>
      window.addEventListener("load", function () {
        const config = {}; //paste config here
        const svgString = window.qrcode.generateSVGString(config);
        document.getElementById("svg-container").innerHTML = svgString;
      });
    </script>
  </html>
  `,
};
const PlatformCode = ({ id }: { id: Platform }) => {
  return (
    <div style={{ position: "relative", marginTop: -7 }}>
      <button
        onClick={() => {
          copy(CODES[id]);
          toast.success("Code copied!", {
            position: "top-center",
          });
        }}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 99,
          backgroundColor: "transparent",
          padding: 20,
          cursor: "pointer",
        }}
      >
        <img src={CopyPNG} alt="Copy to clipboard" height={20} />
      </button>
      <SyntaxHighlighter
        language="javascript"
        style={materialDark}
        customStyle={{
          width: "100%",
          fontSize: 14,
          borderRadius: 4,
          minHeight: 200,
        }}
      >
        {CODES[id]}
      </SyntaxHighlighter>
    </div>
  );
};
export const CodeBlock = ({ config }: CodeBlockProps) => {
  const TABS = {
    React: {
      id: "react",
    },
    "React Native": {
      id: "react-native",
    },
    VueJS: {
      id: "vue",
    },
    Angular: {
      id: "angular",
    },
    VanillaJS: {
      id: "vanilla",
    },
    "Node.js": {
      id: "node",
    },
  };

  const CONFIG_TABS = {
    Typescript: {
      code: `const config = ${JSON.stringify(
        {
          ...config,
          errorCorrectionLevel: `${config.errorCorrectionLevel}as const`,
          shapes: {
            eyeFrame: `${config.shapes.eyeFrame}as const`,
            eyeball: `${config.shapes.eyeball}as const`,
            body: `${config.shapes.body}as const`,
          },
        },
        undefined,
        4
      )}`.replace(/as const"/gm, `" as const`),
    },
    JavaScript: {
      code: `const config = ${JSON.stringify(config, undefined, 4)}`,
    },
  };

  const [activeTab, setActiveTab] = useState<keyof typeof TABS>("React");
  const [activeConfigTab, setActiveConfigTab] =
    useState<keyof typeof CONFIG_TABS>("Typescript");

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div style={{ width: "100%", position: "relative" }}>
        <button
          onClick={() => {
            copy("npm i @intosoft/qrcode");
            toast.success("Code copied!", {
              position: "top-center",
            });
          }}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 99,
            backgroundColor: "transparent",
            padding: 20,
            cursor: "pointer",
          }}
        >
          <img src={CopyPNG} alt="Copy to clipboard" height={20} />
        </button>
        <SyntaxHighlighter
          language="bash"
          style={materialDark}
          customStyle={{
            width: "100%",
            fontSize: 14,
            borderRadius: 4,
          }}
        >
          npm i @intosoft/qrcode
        </SyntaxHighlighter>
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <button
          onClick={() => {
            copy("yarn add @intosoft/qrcode");
            toast.success("Code copied!", {
              position: "top-center",
            });
          }}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 99,
            backgroundColor: "transparent",
            padding: 20,
            cursor: "pointer",
          }}
        >
          <img src={CopyPNG} alt="Copy to clipboard" height={20} />
        </button>
        <SyntaxHighlighter
          language="bash"
          style={materialDark}
          customStyle={{
            width: "100%",
            fontSize: 14,
            borderRadius: 4,
          }}
        >
          yarn add @intosoft/qrcode
        </SyntaxHighlighter>
      </div>
      <Tabs
        tabs={Object.keys(TABS)}
        activeTab={activeTab}
        //@ts-ignore
        onChange={(tab) => setActiveTab(tab)}
      />
      {/* @ts-ignore */}
      <PlatformCode id={TABS[activeTab].id} />
      <div style={{ width: "100%", position: "relative" }}>
        <button
          onClick={() => {
            copy(CONFIG_TABS[activeConfigTab].code);
            toast.success("Config copied!", {
              position: "top-center",
            });
          }}
          style={{
            position: "absolute",
            right: 0,
            top: 45,
            zIndex: 99,
            backgroundColor: "transparent",
            padding: 20,
            cursor: "pointer",
          }}
        >
          <img src={CopyPNG} alt="Copy to clipboard" height={20} />
        </button>

        <Tabs
          tabs={Object.keys(CONFIG_TABS)}
          activeTab={activeConfigTab}
          //@ts-ignore
          onChange={(tab) => setActiveConfigTab(tab)}
        />
        <SyntaxHighlighter
          language="javascript"
          style={materialDark}
          customStyle={{
            width: "100%",
            fontSize: 14,
            borderRadius: 4,
          }}
        >
          {CONFIG_TABS[activeConfigTab].code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};
