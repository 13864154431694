import styled from "styled-components";

import { CustomizationSectionProps } from "../type";
import { fileToBase64 } from "../../../../utils/file";
import UploadSVG from "../../../../assets/icons/upload-solid.svg";
import CloseSVG from "../../../../assets/icons/close.svg";
import Slider from "rc-slider";
import { Input, Label } from "./style";
import { useState } from "react";

const Upload = styled.div`
  height: 80px;
  width: 100px;
  border: 1px solid #d0d7df;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Logo = ({ setQrConfig, qrConfig }: CustomizationSectionProps) => {
  const [uploadedLogo, setUploadedLogo] = useState<string | undefined>();
  return (
    <div>
      <Label>Enter your image UR or base64 string</Label>
      <Input
        value={qrConfig.logo?.url}
        onChange={({ target: { value } }) =>
          setQrConfig({
            ...qrConfig,
            logo: {
              ...(qrConfig.logo || { url: "", size: 4, removeBg: false }),
              url: value,
            },
          })
        }
      />

      <p
        style={{
          textAlign: "center",
          margin: 10,
        }}
      >
        OR
      </p>
      <Label>Upload Image</Label>
      <div
        style={{
          position: "relative",
          width: "100px",
        }}
      >
        <Upload>
          {!uploadedLogo && (
            <input
              value={uploadedLogo}
              type="file"
              accept="image/*"
              style={{
                height: "100%",
                width: "100%",
                zIndex: 99,
                opacity: 0,
                position: "absolute",
              }}
              onChange={async ({ target: { files } }) => {
                if (files?.[0]) {
                  const base64 = await fileToBase64(files[0]);
                  setUploadedLogo(base64);
                  setQrConfig({
                    ...qrConfig,
                    logo: {
                      ...(qrConfig.logo || {
                        url: "",
                        size: 4,
                        removeBg: false,
                      }),
                      url: base64,
                    },
                  });
                }
              }}
            />
          )}
          <img
            src={uploadedLogo || UploadSVG}
            alt="Upload icon"
            style={{
              width: uploadedLogo ? "100%" : 40,
              objectFit: "cover",
            }}
          />
        </Upload>
        {!!uploadedLogo && (
          <div
            onClick={() => {
              setUploadedLogo(undefined);
              setQrConfig({
                ...qrConfig,
                logo: { url: "", size: 4, removeBg: false },
              });
            }}
            style={{
              height: 20,
              position: "absolute",
              top: -7,
              right: -7,
              zIndex: 999,
            }}
          >
            <img
              src={CloseSVG}
              alt="close icon"
              style={{
                height: 20,
              }}
            />
          </div>
        )}
      </div>
      <p
        style={{
          margin: "20px 0px",
        }}
      >
        Background
      </p>

      <p>Remove background behind logo</p>
      <input
        type="checkbox"
        checked={qrConfig.logo?.removeBg}
        onChange={() => {
          setQrConfig({
            ...qrConfig,
            logo: {
              ...(qrConfig.logo || { url: "", size: 4, removeBg: false }),
              removeBg: !qrConfig.logo?.removeBg,
            },
          });
        }}
      />

      <p
        style={{
          margin: "20px 0px",
        }}
      >
        Dimensions
      </p>
      <div
        style={{
          width: 200,
        }}
      >
        <Slider
          min={2}
          max={16}
          onChange={(val) =>
            setQrConfig({
              ...qrConfig,
              logo: {
                ...(qrConfig.logo || { url: "", size: 4, removeBg: false }),
                size: Number(val),
              },
            })
          }
          value={qrConfig.logo?.size}
          styles={{
            track: {
              background: "#03C29C",
            },
            handle: {
              background: "#03C29C",
            },
          }}
        />
      </div>
    </div>
  );
};
