import styled from "styled-components";

import { CustomizationSectionProps } from "../type";
import { useEffect, useState } from "react";
import { Input, Select, Label, Row } from "./style";
import { generateContentString } from "@qrcode";

const Item = styled.div``;

const ENCRYPTION_OPTIONS: {
  id: string;
  value: string;
}[] = [
  {
    id: "nopass",
    value: "No encryption",
  },
  {
    id: "WPA",
    value: "WPA/WPA2",
  },
  {
    id: "WEP",
    value: "WEP",
  },
];

export const WifiForm = ({
  setQrConfig,
  qrConfig,
}: CustomizationSectionProps) => {
  const [content, setContent] = useState<{
    ssid: string;
    password: string;
    encryption: string;
  }>({
    ssid: "intosoft",
    password: "@intosoft",
    encryption: "nopass",
  });

  const generateString = () => {
    const dataString = generateContentString("wifi", content as any);

    setQrConfig({
      ...qrConfig,
      value: dataString,
    });
  };

  useEffect(() => {
    generateString();
  }, [content]);

  return (
    <>
      <Row>
        <Item>
          <Label>Wireless SSID</Label>
          <Input
            value={content.ssid}
            onChange={({ target: { value } }) => {
              setContent((prev) => ({
                ...prev,
                ssid: value,
              }));
            }}
          />
        </Item>
        <Item>
          <Label>Password</Label>
          <Input
            value={content.password}
            onChange={({ target: { value } }) =>
              setContent((prev) => ({
                ...prev,
                password: value,
              }))
            }
          />
        </Item>
        <Item>
          <Label>Encryption</Label>
          <Select
            value={content.encryption}
            onChange={({ target: { value } }) =>
              setContent((prev) => ({
                ...prev,
                encryption: value,
              }))
            }
          >
            {ENCRYPTION_OPTIONS.map((item) => (
              <option key={item.id} value={item.id}>
                {item.value}
              </option>
            ))}
          </Select>
        </Item>
      </Row>
    </>
  );
};
