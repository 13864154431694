import { CustomizationSectionProps } from "../type";
import { useEffect, useState } from "react";
import { Input, Label } from "./style";
import { generateContentString } from "@qrcode";
export const PhoneForm = ({
  setQrConfig,
  qrConfig,
}: CustomizationSectionProps) => {
  const [content, setContent] = useState("980");

  const generateString = () => {
    setQrConfig({
      ...qrConfig,
      value: generateContentString("tel", content),
    });
  };

  useEffect(() => {
    generateString();
  }, [content]);

  return (
    <>
      <>
        <Label>Phone number</Label>
        <Input
          value={content}
          onChange={({ target: { value } }) => setContent(value)}
        />
      </>
    </>
  );
};
