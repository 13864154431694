import styled from "styled-components";

interface TabsProps {
  tabs: string[];
  activeTab: string;
  onChange: (val: string) => void;
}

const StyledTabs = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  max-width: 100vw;
  overflow-y: hidden;
`;
const Tab = styled.div<{
  $active: boolean;
}>`
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  padding: 4px 20px;
  border-bottom: 2px solid
    ${({ $active }) => ($active ? "#48C39C" : "transparent")};
  color: ${({ $active }) => ($active ? "#48C39C" : "black")};
  font-size: 14px;
  margin-top: 10px;
  white-space: nowrap;
`;

export const Tabs = ({ activeTab, tabs, onChange }: TabsProps) => {
  return (
    <StyledTabs>
      {tabs.map((tab) => (
        <Tab
          $active={activeTab === tab}
          key={tab}
          onClick={() => onChange(tab)}
        >
          {tab}
        </Tab>
      ))}
    </StyledTabs>
  );
};
