import styled from "styled-components";

import logo from "../assets/icons/logo.png";
import facebookSVG from "../assets/icons/facebook.svg";
import githubSVG from "../assets/icons/github.svg";
import linkedInSVG from "../assets/icons/linkedin.svg";

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
`;

const Logo = styled.img`
  height: 30px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px 20px;
`;

const IconBox = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 10px;
  img {
    height: 20px;
  }
`;
export const Footer = () => {
  return (
    <StyledFooter>
      <Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Logo src={logo} alt="Intosoft logo" />
            <p
              style={{
                fontSize: 12,
              }}
            >
              QR Code generation tool
            </p>
          </div>
          <div
            style={{
              marginRight: 10,
            }}
          >
            <p>Contact</p>
            <p style={{ fontSize: 12 }}>hi@intosoft.com</p>

            <IconBox>
              <a
                href="https://www.facebook.com/intosoftcom"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookSVG} alt="Facebook icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/intosoft-com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedInSVG} alt="LinkedIn icon" />
              </a>
              <a
                href="https://github.com/Intosoft"
                target="_blank"
                rel="noreferrer"
              >
                <img src={githubSVG} alt="Github icon" />
              </a>
            </IconBox>
          </div>
        </div>
      </Content>
      <div
        style={{
          backgroundColor: "#dedede",
          width: "100%",
          padding: 8,
        }}
      >
        <p
          style={{
            fontSize: 12,
            textAlign: "center",
          }}
        >
          QR Code is a registered trademark of DENSO WAVE INCORPORATED.
        </p>
      </div>
    </StyledFooter>
  );
};
