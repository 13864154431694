import styled from "styled-components";

import { CustomizationSectionProps } from "./type";

import GradientPicker from "react-best-gradient-color-picker";

import { useState } from "react";
import { isGradientColor } from "@qrcode/utils/gradient";
import { StyledShape } from "./Shape";
import { isTransparent } from "@qrcode/utils";

const Label = styled.p`
  width: 90px;
  font-size: 14px;
`;

const Title = styled.p``;

const ColorPicker = styled.button<{ $value: string }>`
  height: 30px;
  width: 100px;
  border: 2px solid #d0d7df;
  border-radius: 4px;
  cursor: pointer;
  ${({ $value }) =>
    isGradientColor($value)
      ? `background-image: ${$value}`
      : `background-color:${$value}`};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;
const LeftSection = styled.div``;
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  margin-top: 4px;
  padding: 10px;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: #41e08e;
  color: white;
  &:hover {
    background-color: #229c20;
  }
  border-radius: 4px;
`;

const SameAsBody = styled.div``;

type ColorConfigLabel = "Background" | "Body" | "EyeFrame" | "Eyeball";
export const Colors = ({
  setQrConfig,
  qrConfig,
}: CustomizationSectionProps) => {
  const COLOR_CONFIG: {
    label: ColorConfigLabel;
    value: string;
    onChange: (value: string) => void;
    RightComponent?: () => JSX.Element;
  }[] = [
    {
      label: "Background",
      value: qrConfig.colors.background,
      onChange: (value: string) => {
        setQrConfig({
          ...qrConfig,
          colors: {
            ...qrConfig.colors,
            background: value,
          },
        });
      },
      RightComponent: () => (
        <StyledShape
          $active={isTransparent(qrConfig.colors.background)}
          onClick={() =>
            setQrConfig({
              ...qrConfig,
              colors: {
                ...qrConfig.colors,
                background: "transparent",
              },
            })
          }
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            height: "30px",
            borderWidth: 2,
            padding: "0px 10px",
          }}
        >
          <p style={{ fontSize: 12 }}>Transparent</p>
        </StyledShape>
      ),
    },
    {
      label: "Body",
      value: qrConfig.colors.body,
      onChange: (value: string) => {
        setQrConfig({
          ...qrConfig,
          colors: {
            ...qrConfig.colors,
            body: value,
          },
        });
      },
    },
    {
      label: "EyeFrame",
      value: qrConfig.colors.eyeFrame.topLeft,
      onChange: (value: string) => {
        setQrConfig({
          ...qrConfig,
          colors: {
            ...qrConfig.colors,
            eyeFrame: {
              topLeft: value,
              topRight: value,
              bottomLeft: value,
            },
          },
        });
      },
      RightComponent: () => (
        <StyledShape
          $active={qrConfig.colors.eyeFrame.topLeft === "body"}
          onClick={() =>
            setQrConfig({
              ...qrConfig,
              colors: {
                ...qrConfig.colors,
                eyeFrame: {
                  topLeft: "body",
                  topRight: "body",
                  bottomLeft: "body",
                },
              },
            })
          }
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            height: "30px",
            borderWidth: 2,
            padding: "0px 10px",
          }}
        >
          <p style={{ fontSize: 12 }}>Same as body</p>
        </StyledShape>
      ),
    },
    {
      label: "Eyeball",
      value: qrConfig.colors.eyeball.topLeft,
      onChange: (value: string) => {
        setQrConfig({
          ...qrConfig,
          colors: {
            ...qrConfig.colors,
            eyeball: {
              topLeft: value,
              topRight: value,
              bottomLeft: value,
            },
          },
        });
      },
      RightComponent: () => (
        <StyledShape
          $active={qrConfig.colors.eyeball.topLeft === "body"}
          onClick={() =>
            setQrConfig({
              ...qrConfig,
              colors: {
                ...qrConfig.colors,
                eyeball: {
                  topLeft: "body",
                  topRight: "body",
                  bottomLeft: "body",
                },
              },
            })
          }
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            height: "30px",
            borderWidth: 2,
            padding: "0px 10px",
          }}
        >
          <p style={{ fontSize: 12 }}>Same as body</p>
        </StyledShape>
      ),
    },
  ];

  const [activeColorLabel, setActiveColorLabel] = useState<
    ColorConfigLabel | undefined
  >();

  const [color, setColor] = useState("rgba(255,255,255,1)");

  return (
    <Container>
      <LeftSection>
        {COLOR_CONFIG.map(({ RightComponent, ...config }) => (
          <Row key={config.label}>
            <Row>
              <Label>{config.label}</Label>
              <ColorPicker
                $value={config.value}
                onClick={() => {
                  setActiveColorLabel(config.label);
                }}
              />
              {RightComponent && <RightComponent />}
            </Row>
          </Row>
        ))}
      </LeftSection>
      {activeColorLabel && (
        <RightSection>
          <Title
            style={{
              marginBottom: 4,
              marginTop: -10,
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            {activeColorLabel} Color
          </Title>
          <div>
            <GradientPicker
              value={
                COLOR_CONFIG.find((config) => config.label === activeColorLabel)
                  ?.value
              }
              onChange={(value) => {
                console.log(value);
                COLOR_CONFIG.find(
                  (config) => config.label === activeColorLabel
                )?.onChange(value);
              }}
              hideEyeDrop
              hideColorGuide
              hidePresets
              hideAdvancedSliders
              hideInputType
              height={180}
              style={{
                zIndex: 999,
              }}
            />
          </div>
          <Button
            style={{ width: "100%" }}
            onClick={() => setActiveColorLabel(undefined)}
          >
            Done
          </Button>
        </RightSection>
      )}
    </Container>
  );
};
