import styled from "styled-components";

import { CustomizationSectionProps } from "../type";
import { useEffect, useState } from "react";
import { Input, Select, Label, Row, TextArea } from "./style";

export const TextForm = ({
  setQrConfig,
  qrConfig,
}: CustomizationSectionProps) => {
  const [content, setContent] = useState("https://intosoft.com");

  const generateString = () => {
    setQrConfig({
      ...qrConfig,
      value: content,
    });
  };

  useEffect(() => {
    generateString();
  }, [content]);

  return (
    <>
      <>
        <Label>Enter your text or URL</Label>
        <TextArea
          value={content}
          onChange={({ target: { value } }) => setContent(value)}
        />
      </>
    </>
  );
};
