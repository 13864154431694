import body1 from "../../assets/shape/body/1.svg";
import body2 from "../../assets/shape/body/2.svg";
import body3 from "../../assets/shape/body/3.svg";
import body4 from "../../assets/shape/body/4.svg";
import body5 from "../../assets/shape/body/5.svg";
import body6 from "../../assets/shape/body/6.svg";
import body7 from "../../assets/shape/body/7.svg";
import body8 from "../../assets/shape/body/8.svg";
import body9 from "../../assets/shape/body/9.svg";
import body10 from "../../assets/shape/body/10.svg";
import body11 from "../../assets/shape/body/11.svg";

import eyeball1 from "../../assets/shape/eyeball/1.svg";
import eyeball2 from "../../assets/shape/eyeball/2.svg";
import eyeball3 from "../../assets/shape/eyeball/3.svg";
import eyeball4 from "../../assets/shape/eyeball/4.svg";
import eyeball5 from "../../assets/shape/eyeball/5.svg";
import eyeball6 from "../../assets/shape/eyeball/6.svg";
import eyeball7 from "../../assets/shape/eyeball/7.svg";
import eyeball8 from "../../assets/shape/eyeball/8.svg";
import eyeball9 from "../../assets/shape/eyeball/9.svg";
import eyeball10 from "../../assets/shape/eyeball/10.svg";
import eyeball11 from "../../assets/shape/eyeball/11.svg";
import eyeball12 from "../../assets/shape/eyeball/12.svg";
import eyeball13 from "../../assets/shape/eyeball/13.svg";
import eyeball14 from "../../assets/shape/eyeball/14.svg";
import eyeball15 from "../../assets/shape/eyeball/15.svg";
import eyeball16 from "../../assets/shape/eyeball/16.svg";
import eyeball17 from "../../assets/shape/eyeball/17.svg";

import eyeframe1 from "../../assets/shape/eyeframe/1.svg";
import eyeframe2 from "../../assets/shape/eyeframe/2.svg";
import eyeframe3 from "../../assets/shape/eyeframe/3.svg";
import eyeframe4 from "../../assets/shape/eyeframe/4.svg";
import eyeframe5 from "../../assets/shape/eyeframe/5.svg";
import eyeframe6 from "../../assets/shape/eyeframe/6.svg";
import eyeframe7 from "../../assets/shape/eyeframe/7.svg";
import eyeframe8 from "../../assets/shape/eyeframe/8.svg";
import eyeframe9 from "../../assets/shape/eyeframe/9.svg";
import eyeframe10 from "../../assets/shape/eyeframe/10.svg";
import eyeframe11 from "../../assets/shape/eyeframe/11.svg";
import eyeframe12 from "../../assets/shape/eyeframe/12.svg";
import eyeframe13 from "../../assets/shape/eyeframe/13.svg";
import eyeframe14 from "../../assets/shape/eyeframe/14.svg";
import eyeframe15 from "../../assets/shape/eyeframe/15.svg";

import { BodyShape, EyeFrameShape, EyeballShape } from "@qrcode/config";

export const config: {
  body: [BodyShape, string][];
  eyeball: [EyeballShape, string][];
  eyeFrame: [EyeFrameShape, string][];
} = {
  body: [
    ["circle", body1],
    ["circle-small", body2],
    ["square", body3],
    ["square-small", body4],
    ["square-vertical", body5],
    ["square-horizontal", body6],
    ["rounded-horizontal", body7],
    ["rounded-vertical", body8],
    ["diamond", body9],
    ["star", body10],
    ["star-small", body11],
    ["styleA", body11],
    ["styleB", body11],
  ],
  eyeball: [
    ["circle", eyeball1],
    ["square", eyeball2],
    ["rounded", eyeball3],
    ["styleA", eyeball4],
    ["body-circle-small", eyeball5],
    ["styleB", eyeball6],
    ["styleC", eyeball7],
    // ["body-square-horizontal", eyeball8],
    ["body-square-small", eyeball9],
    ["body-square-horizontal", eyeball10],
    ["body-square-vertical", eyeball11],
    ["body-circle", eyeball12],
    ["body-rounded-horizontal", eyeball13],
    ["body-rounded-vertical", eyeball14],
    ["body-diamond", eyeball15],
    // ["body-star", eyeball16],
    // ["body-star-small", eyeball17],
  ],
  eyeFrame: [
    ["rounded", eyeframe1],
    ["styleA", eyeframe2],
    ["styleB", eyeframe3],
    ["circle", eyeframe4],
    ["square", eyeframe5],
    ["body-square-small", eyeframe6],
    ["body-square-horizontal", eyeframe7],
    ["body-square-vertical", eyeframe8],
    ["body-circle", eyeframe9],
    ["body-rounded-horizontal", eyeframe10],
    ["body-rounded-vertical", eyeframe11],
    ["body-diamond", eyeframe12],
    ["body-star", eyeframe13],
    ["body-star-small", eyeframe14],
    ["body-circle-small", eyeframe15],
  ],
};
