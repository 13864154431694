import styled from "styled-components";

import { CustomizationSectionProps } from "../type";
import { useEffect, useState } from "react";
import { Input, Label, Row } from "./style";
import { generateContentString } from "@qrcode";
const Item = styled.div``;

export const LocationForm = ({
  setQrConfig,
  qrConfig,
}: CustomizationSectionProps) => {
  const [content, setContent] = useState({
    latitude: "27.669264274110958",
    longitude: "85.34903364435104",
  });

  const generateString = () => {
    const dataString = generateContentString("location", content);

    setQrConfig({
      ...qrConfig,
      value: dataString,
    });
  };

  useEffect(() => {
    generateString();
  }, [content]);

  return (
    <>
      <Row>
        <Item>
          <Label>Latitude</Label>
          <Input
            value={content.latitude}
            onChange={({ target: { value } }) => {
              setContent((prev) => ({
                ...prev,
                latitude: value,
              }));
            }}
          />
        </Item>
        <Item>
          <Label>Longitude</Label>
          <Input
            type="number"
            value={content.longitude}
            onChange={({ target: { value } }) =>
              setContent((prev) => ({
                ...prev,
                longitude: value,
              }))
            }
          />
        </Item>
      </Row>
    </>
  );
};
