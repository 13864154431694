import styled from "styled-components";

import { config } from "../config";
import { CustomizationSectionProps } from "./type";

const Title = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const ShapeImg = styled.img`
  height: 34px;
  width: 34px;
`;

export const StyledShape = styled.div<{ $active: boolean }>`
  cursor: pointer;
  border: 3px solid ${({ $active }) => ($active ? "#00C39C" : "#ebebeb")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px;
  background-color: #ebebeb;
  transition: all 0.1s ease-in-out;
  user-select: none;
`;

const ShapeWrapper = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Shape = ({ setQrConfig, qrConfig }: CustomizationSectionProps) => {
  return (
    <>
      <Title>Body Shape</Title>
      <ShapeWrapper>
        {config.body.map((item) => (
          <StyledShape
            style={{
              height: 60,
              width: 74,
            }}
            key={item[0]}
            $active={item[0] === qrConfig.shapes.body}
            onClick={() =>
              setQrConfig({
                ...qrConfig,
                shapes: {
                  ...qrConfig.shapes,
                  body: item[0],
                },
              })
            }
          >
            <ShapeImg
              src={item[1]}
              style={{
                height: "130%",
                width: "130%",
              }}
            />
          </StyledShape>
        ))}
      </ShapeWrapper>
      <Title
        style={{
          marginTop: 20,
        }}
      >
        Eyeball Shape
      </Title>
      <ShapeWrapper>
        {config.eyeball.map((item) => (
          <StyledShape
            key={item[0]}
            $active={item[0] === qrConfig.shapes.eyeball}
            onClick={() =>
              setQrConfig({
                ...qrConfig,
                shapes: {
                  ...qrConfig.shapes,
                  eyeball: item[0],
                },
              })
            }
          >
            <ShapeImg src={item[1]} />
          </StyledShape>
        ))}
        <StyledShape
          $active={"body" === qrConfig.shapes.eyeball}
          onClick={() =>
            setQrConfig({
              ...qrConfig,
              shapes: {
                ...qrConfig.shapes,
                eyeball: "body",
              },
            })
          }
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <p
            style={{
              fontSize: 12,
            }}
          >
            Same as body
          </p>
        </StyledShape>
      </ShapeWrapper>
      <Title
        style={{
          marginTop: 20,
        }}
      >
        Eye Frame Shape
      </Title>
      <ShapeWrapper>
        {config.eyeFrame.map((item) => (
          <StyledShape
            key={item[0]}
            $active={item[0] === qrConfig.shapes.eyeFrame}
            onClick={() =>
              setQrConfig({
                ...qrConfig,
                shapes: {
                  ...qrConfig.shapes,
                  eyeFrame: item[0],
                },
              })
            }
          >
            <ShapeImg src={item[1]} />
          </StyledShape>
        ))}
        <StyledShape
          $active={"body" === qrConfig.shapes.eyeFrame}
          onClick={() =>
            setQrConfig({
              ...qrConfig,
              shapes: {
                ...qrConfig.shapes,
                eyeFrame: "body",
              },
            })
          }
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <p
            style={{
              fontSize: 12,
            }}
          >
            Same as body
          </p>
        </StyledShape>
      </ShapeWrapper>
    </>
  );
};
