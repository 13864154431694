import styled from "styled-components";

import { CustomizationSectionProps } from "./type";
import { Tabs } from "../../../components/Tabs";
import { useState } from "react";
import { WifiForm } from "./forms/Wifi";
import { TextForm } from "./forms/TextURL";
import { EmailForm } from "./forms/Email";
import { PhoneForm } from "./forms/Phone";
import { LocationForm } from "./forms/Location";

const Input = styled.input`
  height: 40px;
  width: 100%;
  border: 1px solid #d0d7df;
  border-radius: 4px;
  padding: 4px 10px;
  outline: none;

  &:focus {
    border: 1px solid #a2e344;
  }
`;

const Label = styled.p`
  margin: 4px 2px;
`;

export const Content = ({
  setQrConfig,
  qrConfig,
}: CustomizationSectionProps) => {
  const TABS = {
    "Text/URL": {
      Component: TextForm,
    },
    "WI-FI": {
      Component: WifiForm,
    },
    Phone: {
      Component: PhoneForm,
    },
    Email: {
      Component: EmailForm,
    },
    Location: {
      Component: LocationForm,
    },
  };

  const [activeTab, setActiveTab] = useState("Text/URL");
  //@ts-ignore
  const FormComponent = TABS[activeTab].Component;
  return (
    <>
      <Tabs
        tabs={Object.keys(TABS)}
        activeTab={activeTab}
        onChange={(val) => setActiveTab(val)}
      ></Tabs>
      <FormComponent qrConfig={qrConfig} setQrConfig={setQrConfig} />
    </>
  );
};
