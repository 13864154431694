import styled from "styled-components";

export const Input = styled.input`
  height: 40px;
  width: 100%;
  border: 1px solid #d0d7df;
  border-radius: 4px;
  padding: 4px 10px;
  outline: none;

  &:focus {
    border: 1px solid #a2e344;
  }
`;

export const Select = styled.select`
  height: 40px;
  width: 100%;
  border: 1px solid #d0d7df;
  border-radius: 4px;
  padding: 4px 10px;
  outline: none;

  &:focus {
    border: 1px solid #a2e344;
  }
`;

export const Label = styled.p`
  margin: 10px 2px 4px;
  font-size: 12px;
`;

export const Row = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

export const TextArea = styled.textarea`
  min-height: 40px;
  height: 100px;
  width: 100%;
  border: 1px solid #d0d7df;
  border-radius: 4px;
  padding: 4px 10px;
  outline: none;

  &:focus {
    border: 1px solid #a2e344;
  }
`;
