import styled from "styled-components";
import packageJSON from "../../../package.json";
import { SVG } from "../../components/SVG";
import { useEffect, useState } from "react";
import { generateSVGString } from "@qrcode";
import jsQR from "jsqr";

import { downloadSVG } from "../../utils/file";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Tabs } from "../../components/Tabs";
import { BrowserQRCodeReader } from "@zxing/browser";
import { Shape } from "./customization/Shape";
import { Colors } from "./customization/Colors";
import { Logo } from "./customization/forms/Logo";
import { Config } from "@qrcode/config";
import { CodeBlock } from "./Code";
import { Content as ContentTab } from "./customization/Content";
import { CONFIGS } from "./data";
import useWindowDimension from "../../hooks/useWindowDimension";
import { TextContent } from "./TextContent";
const randomIndex = Math.floor(Math.random() * CONFIGS.length);
const currentConfig = CONFIGS[randomIndex];

const defaultConfig: Config = {
  length: 300,
  padding: 20,
  errorCorrectionLevel: "H",
  value: "https://intosoft.com",
  logo: {
    url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjIuMSAxOTQuNyI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiM0MmM5ODU7fS5jbHMtMntmaWxsOiM2NmJmODM7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNOTQuMzYsNzUuNTljMTguMzksMTkuNzUsMTguNzYsNDkuMjguODIsNjZsLTUuMjQsNC44Ny03LjQ5LDctMy4xNSwzLjEyTDQwLjQ3LDE5Mi44N2E2Ljc3LDYuNzcsMCwwLDEtOS41Ny0uMzFMMCwxNTkuNTVsMTguNi0xNy40MWEyMi43OCwyMi43OCwwLDAsMSwxLjU5LTEuNjhsLjE1LS4xNCw4LjE3LTcuNjIsOS4wOS04LjQ3LjkyLS44NywxMS4yNy0xMC41MWExOSwxOSwwLDAsMCwxMi40NSwzLjYzLDE5LjE2LDE5LjE2LDAsMSwwLTE3LjQ0LTlMMzEuMTQsMTIwLjI1QTEuODEsMS44MSwwLDAsMSwyOC42LDEyMGwtLjg2LS45MkM5LjM1LDk5LjM3LDksNjkuODQsMjYuOTIsNTMuMTZMMzkuNjUsNDEuMzFsMzgtMzUuNzZMNzIsMTAuODhsOS42NC05LjA2YTYuNzMsNi43MywwLDAsMSw5LjUyLjMxbDMwLjkyLDMzTDEwMy41LDUyLjU3YTIwLjg1LDIwLjg1LDAsMCwxLTEuNTksMS42OGwtLjE1LjE0TDkzLjYsNjIsODcuMDcsNjguMWw2LjE1LDYuMzJaIi8+PHBhdGggY2xhc3M9ImNscy0yIiBkPSJNOTQuMzYsNzUuNTlsLTEuMTQtMS4xN2EyLjI2LDIuMjYsMCwwLDEsLjI5LjI1WiIvPjwvZz48L2c+PC9zdmc+",
    size: 11,
    removeBg: true,
  },
  shapes: {
    eyeFrame: "circle",
    body: "circle",
    eyeball: "circle",
  },
  colors: {
    background: "transparent",
    body: "linear-gradient(90deg, rgba(105,16,16,1) 1%, rgba(0,212,255,1) 100%)",
    eyeFrame: {
      topLeft: "rgb(86, 146, 86)",
      topRight: "rgb(86, 146, 86)",
      bottomLeft: "rgb(86, 146, 86)",
    },
    eyeball: {
      topLeft: "rgb(63, 9, 9)",
      topRight: "rgb(63, 9, 9)",
      bottomLeft: "rgb(63, 9, 9)",
    },
  },
};

const Container = styled.div`
  padding-top: 50px;
  background-color: #f7f8fa;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CustomizeSection = styled.div`
  background-color: white;
  border: 1px solid #d0d7df;
  border-right: 0;
  width: 100%;
  padding: 20px 20px 30px 20px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const QR = styled.div`
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d0d7df;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  max-width: 340px;
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;
const Content = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const CustomizationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
const Title = styled.p`
  font-size: 16px;
  margin-top: 20px;
`;

const DownloadSection = styled.div`
  border-top: 1px solid #d0d7df;
  margin-top: 10px;
  padding: 10px;
  gap: 5px;
  display: flex;
  justify-content: center;
`;

export const DownloadButton = styled.button`
  padding: 6px 10px;
  color: black;
  border-radius: 2px;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    background-color: #41e08e;
    color: white;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HomePage = () => {
  const TABS = {
    Shape: {
      Component: Shape,
    },
    Colors: {
      Component: Colors,
    },
    Content: {
      Component: ContentTab,
    },
    Logo: {
      Component: Logo,
    },
  };

  const { width: windowWidth } = useWindowDimension();

  const [qrConfig, setQrConfig] = useState<Config>(
    currentConfig || defaultConfig
  );
  const [svgString, setSvgString] = useState(
    generateSVGString({
      ...qrConfig,
    })
  );

  const [activeTab, setActiveTab] = useState("Shape");
  const [isQRScannable, setIsQRScannable] = useState(true);

  const [imageSize, setImageSize] = useState(1000);

  const checkIsScannable = (svgString: string, content: string) => {
    const img = new Image();

    img.src = "data:image/svg+xml;base64," + btoa(svgString);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas?.getContext("2d");
      const { width, height } = img;

      canvas.width = width;
      canvas.height = height;
      ctx?.drawImage(img, 0, 0);

      const imageData = ctx?.getImageData(0, 0, width, height);
      let isJSQRScanned = false;
      if (imageData?.data) {
        const codeReader = new BrowserQRCodeReader();
        const scannedData = jsQR(imageData?.data, width, height) || "";

        if (typeof scannedData === "object") {
          isJSQRScanned = scannedData?.data === content;
        }
        codeReader
          .decodeFromImageUrl(canvas.toDataURL())
          .then((result) => {
            const isZlingSCanned = result.getText() === content;
            setIsQRScannable(isJSQRScanned || isZlingSCanned);
          })
          .catch((err) => {
            // console.error(err);
            setIsQRScannable(isJSQRScanned);
          });
      }
    };
  };
  useEffect(() => {
    const svgString = generateSVGString(qrConfig);
    setSvgString(svgString);
    checkIsScannable(svgString, qrConfig.value);
  }, [qrConfig]);

  const handleSetConfig = (config: Config) => {
    localStorage.setItem(
      "qr-config",
      JSON.stringify({
        config: {
          ...config,
        },
        version: packageJSON.version,
      })
    );

    setQrConfig(config);
  };

  useEffect(() => {
    const _config = localStorage.getItem("qr-config");
    if (_config) {
      try {
        // const { config, version } = JSON.parse(_config);
        // if (version === packageJSON.version) {
        //   setQrConfig(config);
        // }
      } catch (err) {
        // console.log("Err", err);
      }
    }
  }, []);

  useEffect(() => {
    if (windowWidth < 240) {
      setQrConfig((prev) => ({
        ...prev,
        length: windowWidth - 80,
      }));
    } else {
      setQrConfig((prev) => ({
        ...prev,
        length: 300,
      }));
    }
  }, [windowWidth]);

  //@ts-ignore
  const CurrentTabComp = TABS[activeTab].Component;

  return (
    <Container>
      <Content>
        <Tabs
          tabs={Object.keys(TABS)}
          activeTab={activeTab}
          onChange={(val) => setActiveTab(val)}
        ></Tabs>

        <CustomizationWrapper>
          <CustomizeSection>
            <CurrentTabComp {...{ qrConfig, setQrConfig: handleSetConfig }} />
          </CustomizeSection>

          <QR>
            <SVG svgString={svgString} />
            <p
              style={{
                color: isQRScannable ? "#43C985" : "orange",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {isQRScannable
                ? "QR Code is Scannable"
                : "QR Code is not widely scannable"}
            </p>
            <Row
              style={{
                marginBottom: 2,
                width: "100%",
              }}
            >
              <Title
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                Low
              </Title>
              <Title
                style={{
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                {imageSize} x {imageSize} px
              </Title>
              <Title
                style={{
                  textAlign: "center",
                  fontSize: 12,
                }}
              >
                High
              </Title>
            </Row>
            <Slider
              min={100}
              max={3200}
              onChange={(val) => setImageSize(val as number)}
              value={imageSize}
              styles={{
                track: {
                  background: "#03C29C",
                },
                handle: {
                  background: "#03C29C",
                },
              }}
            />
            <Title
              style={{
                textAlign: "center",
              }}
            >
              Download
            </Title>

            <DownloadSection>
              <DownloadButton
                style={{
                  backgroundColor: "#43C985",
                  color: "white",
                }}
                onClick={() =>
                  downloadSVG({
                    config: qrConfig,
                    imageSize,
                    downloadType: "png",
                  })
                }
              >
                Download PNG
              </DownloadButton>
              <DownloadButton
                onClick={() =>
                  downloadSVG({
                    config: qrConfig,
                    imageSize,
                    downloadType: "jpeg",
                  })
                }
              >
                JPEG
              </DownloadButton>
              <DownloadButton
                onClick={() =>
                  downloadSVG({
                    config: qrConfig,
                    imageSize,
                    downloadType: "svg",
                  })
                }
              >
                SVG
              </DownloadButton>
            </DownloadSection>
          </QR>
        </CustomizationWrapper>
        <CodeBlock config={qrConfig} />
        <TextContent />
      </Content>
    </Container>
  );
};
