import styled from "styled-components";

const Container = styled.div`
  padding: 40px 0px;
  font-size: 14px;
`;

export const TextContent = () => {
  return (
    <Container>
      <h1
        style={{
          textAlign: "center",
          fontSize: 22,
          marginTop: 40,
        }}
      >
        Open Source QR Code Customization Tool for JavaScript
      </h1>
      <div
        style={{
          display: "flex",
        }}
      >
        <p
          style={{
            textAlign: "center",
            marginTop: 20,
            maxWidth: 1000,
          }}
        >
          CustoQR is an open-source QR code customization tool designed
          specifically for the JavaScript ecosystem. Whether you're using React,
          React Native, Vue.js, Angular, Node.js, or Vanilla JavaScript, CustoQR
          provides the flexibility and customization options you need to create
          stunning QR codes for your projects.
        </p>
        <p></p>
      </div>
    </Container>
  );
};
