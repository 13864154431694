import styled from "styled-components";

import { CustomizationSectionProps } from "../type";
import { useEffect, useState } from "react";
import { generateContentString } from "@qrcode";
import { Input, Label, TextArea } from "./style";

const Item = styled.div``;

export const EmailForm = ({
  setQrConfig,
  qrConfig,
}: CustomizationSectionProps) => {
  const [content, setContent] = useState({
    email: "mail@intosoft.com",
    subject: "Intro",
    message: "Hello Intosoft",
  });

  const generateString = () => {
    const dataString = generateContentString("email", content);

    setQrConfig({
      ...qrConfig,
      value: dataString,
    });
  };

  useEffect(() => {
    generateString();
  }, [content]);

  return (
    <>
      <Item>
        <Label>Email</Label>
        <Input
          value={content.email}
          onChange={({ target: { value } }) => {
            setContent((prev) => ({
              ...prev,
              email: value,
            }));
          }}
        />
      </Item>
      <Item>
        <Label>Subject</Label>
        <Input
          value={content.subject}
          onChange={({ target: { value } }) =>
            setContent((prev) => ({
              ...prev,
              subject: value,
            }))
          }
        />
      </Item>
      <Item>
        <Label>Message</Label>
        <TextArea
          value={content.message}
          onChange={({ target: { value } }) =>
            setContent((prev) => ({
              ...prev,
              message: value,
            }))
          }
        />
      </Item>
    </>
  );
};
