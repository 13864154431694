import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
   * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
   }

   button {
      outline:none;
      border:none;
   }

   img {
      pointer-events: none;
   }

   a {
      text-decoration: none;
     
   }
`;
